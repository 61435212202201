'use client'

import { cn } from '@/lib/utils'
import CloudinaryImage from '@/components/CloudinaryImage'

function IconLogo({ className, ...props }: React.ComponentProps<'div'>) {
  return (
    <div style={{ width: '24px', height: '24px', position: 'relative' }} {...props}>
      <CloudinaryImage
        src="/smarter-travel-512px.png"
        alt="Smarter Travel Logo"
        fill
        className={cn('h-4 w-4', className)}
        format="auto"
      />
    </div>
  )
}

export { IconLogo }