'use client';

import React from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useSession, signOut } from 'next-auth/react'
import { ModeToggle } from './mode-toggle'
import { IconLogo } from './ui/icons'
import { cn } from '@/lib/utils'
const HistoryContainer = dynamic(() => import('./history-container'), { ssr: false })
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { ChevronDown, User, LogOut } from 'lucide-react'

import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@/components/ui/dialog'
import { FeedbackForm } from '@/components/FeedbackForm'

const tools = [
  { name: 'Packing List Generator', href: '/tools/packing-list-generator' },
  { name: 'Language Phrasebook Generator', href: '/tools/language-phrasebook-generator' },
  { name: 'Travel Insurance Estimator', href: '/tools/travel-insurance-estimator' },
  { name: 'Travel Checklist Generator', href: '/tools/travel-checklist-generator' },
  { name: 'Vacation Cost Calculator', href: '/tools/vacation-cost-calculator' },
  { name: 'Travel Journal Template Generator', href: '/tools/travel-journal-template-generator' },
  { name: 'Getaway Comparison Tool', href: '/tools/getaway-comparison-tool' },
]

export const Header: React.FC = () => {
  const { data: session } = useSession()

  return (
    <header className="fixed top-0 left-0 right-0 w-full py-2 px-4 md:px-6 flex justify-between items-center z-10 bg-background/80 backdrop-blur border-b border-border">
      <div className="flex items-center space-x-6">
        <Link href="/" className="flex items-center space-x-2">
          <IconLogo className="w-8 h-8" />
        </Link>
        <nav className="hidden md:flex space-x-4">
          <Link href="/destinations" className="inline-flex items-center justify-center text-sm font-medium text-foreground/80 hover:text-foreground transition-colors">
            Destinations
          </Link>
          <Link href="/how-it-works" className="text-sm font-medium text-foreground/80 hover:text-foreground transition-colors">
            How It Works
          </Link>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="p-0 h-auto text-sm font-medium text-foreground/80 hover:text-foreground">
                Tools
                <ChevronDown className="ml-1 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-56">
              {tools.map((tool) => (
                <DropdownMenuItem key={tool.href} className="p-0">
                  <Link href={tool.href} className="w-full px-2 py-1.5 text-sm">
                    {tool.name}
                  </Link>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </nav>
      </div>
      <div className="flex items-center space-x-4">
        {session ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <User className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-56">
              <DropdownMenuItem className="font-semibold">
                {session.user?.email}
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <Link href="/profile" className="w-full flex items-center">
                  <User className="mr-2 h-4 w-4" />
                  <span>Profile</span>
                </Link>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onSelect={() => signOut()}>
                <div className="flex items-center w-full">
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </div>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <div className="flex items-center justify-center pt-0.5">
                <span className="text-xs text-muted-foreground font-mono">Beta</span>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <Link href="/sign-in" className="inline-flex items-center justify-center text-sm font-medium text-foreground/80 hover:text-foreground transition-colors px-3 py-2">
            Sign In
          </Link>
        )}
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline" size="sm" className="rounded-full text-xs">
              Feedback
            </Button>
          </DialogTrigger>
          <DialogContent>
            <FeedbackForm onClose={() => {}} />
          </DialogContent>
        </Dialog>
        <ModeToggle />
        <HistoryContainer location="header" />
      </div>
    </header>
  )
}

export default Header