'use client';

import { Session } from 'next-auth';
import { ReactNode } from 'react';
import { SessionProvider } from "next-auth/react";
import { ThemeProvider } from '@/components/theme-provider';
import { AppStateProvider } from '@/lib/utils/app-state';
import Header from '@/components/header'
import Footer from '@/components/footer'
import { Sidebar } from '@/components/sidebar'
import { Toaster } from '@/components/ui/sonner'
import { Analytics } from '@vercel/analytics/react'
import { ErrorBoundary } from 'react-error-boundary'
import ClientErrorFallback from '@/components/ClientErrorFallback'

interface ProvidersProps {
  children: ReactNode;
  session: Session | null;
}

export function Providers({ children, session }: ProvidersProps) {
  return (
    <SessionProvider session={session}>
      <ThemeProvider
        attribute="class"
        defaultTheme="system"
        enableSystem
        disableTransitionOnChange
      >
        <AppStateProvider>
          <ErrorBoundary FallbackComponent={ClientErrorFallback}>
            <Header />
            <Sidebar />
            {children}
            <Footer relatedDestinations={[]} />
            <Toaster />
            <Analytics />
          </ErrorBoundary>
        </AppStateProvider>
      </ThemeProvider>
    </SessionProvider>
  );
}
