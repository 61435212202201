import React from 'react'
import Link from 'next/link'
import { SiFacebook, SiInstagram } from 'react-icons/si'
import { Button } from './ui/button'

interface FooterProps {
  relatedDestinations: any[]; // Replace 'any' with the correct type
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ relatedDestinations, className }) => {
  return (
    <footer className={`w-full p-2 md:p-4 bg-background/80 backdrop-blur border-t border-border ${className}`}>
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center min-h-[60px]">
        <div className="flex space-x-4 mb-4 sm:mb-0">
          <Link href="/blog" className="text-sm font-medium text-foreground/80 hover:text-foreground transition-colors">
            Blog
          </Link>
          <Link href="/next-gen-travel-search" className="text-sm font-medium text-foreground/80 hover:text-foreground transition-colors">
            Travel Search
          </Link>
          <Link href="/best-of" className="text-sm font-medium text-foreground/80 hover:text-foreground transition-colors">
            Best Of
          </Link>
        </div>
        <div className="flex space-x-2">
          <Button
            variant={'ghost'}
            size={'icon'}
            className="text-foreground/50 hover:text-foreground"
          >
            <Link href="https://www.facebook.com/smartertravelai" target="_blank" rel="noopener noreferrer">
              <SiFacebook size={18} />
            </Link>
          </Button>
          <Button
            variant={'ghost'}
            size={'icon'}
            className="text-foreground/50 hover:text-foreground"
          >
            <Link href="https://www.instagram.com/smartertravelai/" target="_blank" rel="noopener noreferrer">
              <SiInstagram size={18} />
            </Link>
          </Button>
        </div>
      </div>
    </footer>
  )
}

export default Footer
